export const BADGES = {
  FIRST_RATING: {
    id: "first_rating",
    name: "First Rating",
    description: "Rated your first game!",
    icon: "/badges/goldstarbadge.png",
  },
  FIRST_REVIEW: {
    id: "first_review",
    name: "First Review",
    description: "Wrote your first review!",
    icon: "/badges/reviewbadge.png",
  },
  FIRST_FAVORITE: {
    id: "first_favorite",
    name: "First Favorite",
    description: "Added your first game to favorites!",
    icon: "/badges/favobadge.png",
  },
  REVIEWER_NOVICE: {
    id: "reviewer_novice",
    name: "Reviewer Novice",
    description: "Wrote 5 reviews!",
    icon: "/badges/reviewnovicebadge.png",
  },
  REVIEWER_EXTRAORDINAIRE: {
    id: "reviewer_extraordinaire",
    name: "Reviewer Extraordinaire",
    description: "Wrote 10+ reviews!",
    icon: "/badges/reviewextrabadge.png",
  },
  ACTIVE_GAMER: {
    id: "active_gamer",
    name: "Active Gamer",
    description: "You rated 10+ games!",
    icon: "/badges/ActiveGamerbadge.png",
  },
  CONNECTOR: {
    id: "connector",
    name: "Connector",
    description: "Referred your first friend!",
    icon: "/badges/Connectorbadge.png",
  },
  COMMUNTY_CHAMP: {
    id: "community_champ",
    name: "Communtiy Champ",
    description: "You have signed in at least 7 days in a row!",
    icon: "/badges/communitychampbadge.png",
  },
};
