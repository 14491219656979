const allgames = [
  {
    id: 31,
    title: "Astlibra Revision",
    imageUrl: "/screenshots2/astmain.jpg",
    description:
      "A long-hidden indie gem that took years to develop, Astlibra Revision is an old-school action RPG with deep combat mechanics, time-travel storytelling, and incredible pixel art. Fans of Castlevania and Ys will love it. ",
    developer: "By Keizo",
    genre: "Genre: Action RPG",
    release: "Release Date: October 13, 2022",
    platform: ["PC"],
    bannerImage: "/screenshots2/astbanner.jpg",
    screenshots: [
      "/screenshots2/ast1.jpg",
      "/screenshots2/ast2.jpg",
      "/screenshots2/ast3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/1718570/ASTLIBRA_Revision/",
  },
  {
    id: 32,
    title: "Bomb Rush Cyberfunk",
    imageUrl: "/screenshots2/bombmain.jpg",
    description:
      "A spiritual successor to Jet Set Radio, Bomb Rush Cyberfunk delivers high-energy graffiti-tagging, skating, and breakdancing in a stylish cel-shaded world. The soundtrack is phenomenal, and the movement is buttery smooth. ",
    developer: "By  Team Reptile",
    genre: "Genre: Action, Platformer",
    release: "Release Date:  August 18, 2023",
    platform: ["PC, PlayStation, Xbox, Nintendo Switch"],
    bannerImage: "/screenshots2/bombbanner.jpg",
    screenshots: [
      "/screenshots2/bomb1.jpg",
      "/screenshots2/bomb2.jpg",
      "/screenshots2/bomb3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/1353230/Bomb_Rush_Cyberfunk/",
  },
  {
    id: 33,
    title: "Death Trash",
    imageUrl: "/screenshots2/deathmain.jpg",
    description:
      "An isometric RPG blending grotesque horror, cyberpunk elements, and old-school Fallout-style gameplay. Death Trash is a weird, gory, and fascinating experience that rewards exploration and player freedom. ",
    developer: "By Crafting Legends",
    genre: "Genre: RPG, Action",
    release: "Release Date: August 5, 2021",
    platform: ["PC"],
    bannerImage: "/screenshots2/deathbanner.jpg",
    screenshots: [
      "/screenshots2/death1.jpg",
      "/screenshots2/death2.jpg",
      "/screenshots2/death3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/941460/Death_Trash/",
  },
  {
    id: 34,
    title: "Astronite",
    imageUrl: "/screenshots2/astromain.jpg",
    description:
      "A black-and-white Metroidvania with tight platforming, precise combat, and an eerie yet charming atmosphere. Astronite is a love letter to classic exploration games but with its own distinct identity. ",
    developer: "By Dume Games Studio",
    genre: "Genre: Metroidvania, Platformer",
    release: "Release Date: November 30, 2022",
    platform: ["PC, PlayStation, Xbox, Nintendo Switch"],
    bannerImage: "/screenshots2/astrobanner.jpg",
    screenshots: [
      "/screenshots2/astro1.jpg",
      "/screenshots2/astro2.jpg",
      "/screenshots2/astro3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/1489590/Astronite/",
  },
  {
    id: 35,
    title: "Industria",
    imageUrl: "/screenshots2/industriamain.jpg",
    description:
      "A first-person shooter that blends Half-Life 2-style gameplay with a surreal, alternative-history Cold War setting. It’s an atmospheric journey with haunting visuals and eerie, thought-provoking storytelling. ",
    developer: "By Bleakmill",
    genre: "Genre: FPS, Adventure",
    release: "Release Date: September 30, 2021",
    platform: ["PC, PlayStation, Xbox"],
    bannerImage: "/screenshots2/industriabanner.jpg",
    screenshots: [
      "/screenshots2/industria1.jpg",
      "/screenshots2/industria2.jpg",
      "/screenshots2/industria3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/1172650/INDUSTRIA/",
  },
  {
    id: 36,
    title: "Cassette Beasts",
    imageUrl: "/screenshots2/cassmain.jpg",
    description:
      "A fresh take on the monster-catching genre, Cassette Beasts lets you record creatures onto tapes and fuse them into powerful hybrids. Its retro aesthetic and strategic turn-based combat make it a must-play for Pokémon fans looking for something different. ",
    developer: "By Bytten Studio",
    genre: "Genre: RPG, Monster-Collecting",
    release: "Release Date: April 26, 2023",
    platform: ["PC,  PlayStation, Xbox, Nintendo Switch"],
    bannerImage: "/screenshots2/cassbanner.jpg",
    screenshots: [
      "/screenshots2/cass1.jpg",
      "/screenshots2/cass2.jpg",
      "/screenshots2/cass3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/1321440/Cassette_Beasts/",
  },
  {
    id: 37,
    title: "Sable",
    imageUrl: "/screenshots2/sablemain.jpg",
    description:
      "A breathtaking open-world adventure with a unique art style inspired by Moebius comics. Sable is a non-combat, exploration-focused game with relaxing puzzles and deep world-building. ",
    developer: "By Shedworks",
    genre: "Genre: Adventure, Exploration",
    release: "Release Date: September 23, 2021",
    platform: ["PC, PlayStation, Xbox"],
    bannerImage: "/screenshots2/sablebanner.jpg",
    screenshots: [
      "/screenshots2/sable1.jpg",
      "/screenshots2/sable2.jpg",
      "/screenshots2/sable3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/757310/Sable/",
  },
  {
    id: 38,
    title: "Hunt the Night",
    imageUrl: "/screenshots2/nightmain.jpg",
    description:
      "A gothic, pixel-art action RPG that mixes Bloodborne-style dark fantasy with Hyper Light Drifter’s fast-paced combat. The haunting music and intricate enemy designs make it an underrated must-play. ",
    developer: "By Moonlight Games",
    genre: "Genre: Action RPG",
    release: "Release Date: April 13, 2023",
    platform: ["PC,  PlayStation, Xbox, Nintendo Switch"],
    bannerImage: "/screenshots2/nightbanner.jpg",
    screenshots: [
      "/screenshots2/night1.jpg",
      "/screenshots2/night2.jpg",
      "/screenshots2/night3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/1649740/Hunt_the_Night/",
  },
  {
    id: 39,
    title: "Shadows of Doubt",
    imageUrl: "/screenshots2/shadowsmain.jpg",
    description:
      "A fully simulated, procedurally generated detective game where you investigate murders in a cyberpunk city. Every NPC has a routine, secrets, and personal stories, making for a living, breathing noir mystery. ",
    developer: "By ColePowered Games",
    genre: "Genre: Simulation, Detective RPG",
    release: "Release Date: April 24, 2023",
    platform: ["PC"],
    bannerImage: "/screenshots2/shadowsbanner.jpg",
    screenshots: [
      "/screenshots2/shadows1.jpg",
      "/screenshots2/shadows2.jpg",
      "/screenshots2/shadows3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/986130/Shadows_of_Doubt/",
  },
  {
    id: 40,
    title: "Beneath Oresa",
    imageUrl: "/screenshots2/beneathmain.jpg",
    description:
      "A beautifully stylized deck-building roguelike with dynamic, turn-based combat. Beneath Oresa stands out with its tactical positioning system, stunning visuals, and a unique setting. ",
    developer: "By Broken Spear Inc.",
    genre: "Genre: Roguelike, Card Battler",
    release: "Release Date: November 3, 2022",
    platform: ["PC"],
    bannerImage: "/screenshots2/beneathbanner.jpg",
    screenshots: [
      "/screenshots2/beneath1.jpg",
      "/screenshots2/beneath2.jpg",
      "/screenshots2/beneath3.jpg",
    ],
    playLink: "https://store.steampowered.com/app/1803400/Beneath_Oresa/",
  },
];

export default allgames;
